@import "~react-image-gallery/styles/css/image-gallery.css";


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}


.image-gallery-thumbnails-wrapper
  .image-gallery-thumbnails
  .image-gallery-thumbnails-container {
  position: relative;
  z-index: 1000;
}
.sideImg .image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}
.sideImg .image-gallery-slide .image-gallery-image {
  width: 56.25vh;
}

a {
  text-decoration: none;
  position: relative;
  z-index: 100;
}

p {
  color: #555659;
  font-size: 20px;
  text-align: left;
}
.MuiFormControlLabel-label {
  color: #555659 !important;
  font-size: 20px !important;
  text-align: left !important;
}

.cardImgTrans .image-gallery-svg{
    display: block;
  position: relative;
  top: 20px;
  height: 60px;
  width: 30px;
  transition: all 0.8s ease 0s; 
  transition-delay: 0.1s;
}
.cardImg .image-gallery-svg {
    display: block;
  position: relative;
  top: 200px;
  height: 60px;
  width: 30px;
}

.cardImg .image-gallery-content .image-gallery-slide .image-gallery-image  {
  max-height: 360px !important;
  display: block;
  position: relative;
  top: 200px;
}

.cardImgTrans .image-gallery-content .image-gallery-slide .image-gallery-image  {
  max-height: 360px !important;
  display: block;
  position: relative;
  top: 20px;
  transition: all 0.7s ease 0s;
     transition-delay: 0.1s;
}



.MuiGrid-spacing-xs-3 {
  margin: 0 !important;
}

.MuiGrid-spacing-xs-8 {
  width: 100% !important;
}
.MuiGrid-spacing-xs-3 {
  width: 100% !important;
}

body {
  background: rgb(237, 237, 240);
}
.container {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  /* top: 40%; */
  left: 0;
  margin: 0 auto;
  font-family: "Varela Round", sans-serif;
}
.container p {
  font-size: 20px;
}
.container a {
  display: inline-block;
  position: relative;
  text-align: center;
  color: #136d23;
  text-decoration: none;
  font-size: 20px;
  overflow: hidden;
  top: 5px;
}
.container a:after {
  content: "";
  position: absolute;
  background: #136d23;
  height: 2px;
  width: 0%;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
  transition: 0.35s ease;
}
.container a:hover:after,
.container a:focus:after,
.container a:active:after {
  width: 100%;
}
h1 {
  position: relative;
  text-align: center;
}
.button_container {
  position: fixed;
  top: 2%;
  right: 3%;
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 9999;
  transition: opacity 0.25s ease;
}
.button_container:hover {
  opacity: 0.7;
}
.button_container.active .top {
  transform: translateY(11px) translateX(0) rotate(45deg);
  background: #fff;
}
.button_container.active .middle {
  opacity: 0;
  background: #fff;
}
.button_container.active .bottom {
  transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #fff;
}
.button_container span {
  background: #136d23;
  border: none;
  height: 5px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.35s ease;
  cursor: pointer;
}
.button_container span:nth-of-type(2) {
  top: 11px;
}
.button_container span:nth-of-type(3) {
  top: 22px;
}
.overlay {
  position: fixed;
  background: #136d23;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
}
.overlay.open {
  opacity: 0.9;
  visibility: visible;
  height: 100%;
  z-index: 999;
}
.overlay.open li {
  animation: fadeInRight 0.5s ease forwards;
  animation-delay: 0.35s;
}
.overlay.open li:nth-of-type(2) {
  animation-delay: 0.4s;
}
.overlay.open li:nth-of-type(3) {
  animation-delay: 0.45s;
}
.overlay.open li:nth-of-type(4) {
  animation-delay: 0.5s;
}
.overlay nav {
  position: relative;
  height: 80%;
  top: 52%;
  transform: translateY(-50%);
  font-size: 30px;
  font-family: "Varela Round", serif;
  font-weight: 400;
  text-align: center;
}
.overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%;
}
.overlay ul li {
  display: block;
  height: 18%;
  height: calc(100% / 6);
  min-height: 50px;
  position: relative;
  opacity: 0;
}
.overlay ul li a {
  display: block;
  position: relative;
  color: #fff;
  text-decoration: none;
  overflow: hidden;
}
.overlay ul li a:hover:after,
.overlay ul li a:focus:after,
.overlay ul li a:active:after {
  width: 100%;
}
.overlay ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  transform: translateX(-50%);
  height: 3px;
  background: #fff;
  transition: 0.35s;
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

.MuiTabs-flexContainer {
  display: flex;
  flex-wrap: wrap;
}

.MuiTab-root {
  padding: 4px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 14px !important;
  overflow: hidden;
  position: relative;
  font-size: 0.875rem;
  max-width: 264px;
  min-width: 72px;
  box-sizing: border-box;
  min-height: 38px !important;
  text-align: center;
  flex-shrink: 0;
  font-family: RobotoCondensedR, Arial;
  font-weight: 500;
  line-height: 1.75;
  white-space: normal;
  text-transform: uppercase;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0!important;
  margin-top: 10!important
}

.MuiAccordionDetails-root {
  padding-top: 0 !important;
}
